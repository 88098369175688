<template>
  <div class="store">
    <!-- <tabs-new v-model="game" :options="types" center>
      <template v-slot:730="{ item }">
        <span>{{ item.label }}</span>
      </template>
      <template v-slot:570="{ item }">
        <span>{{ item.label }}</span>
      </template>
    </tabs-new> -->

    <div class="container">
      <b-modal id="order-success" size="md" centered hide-header hide-footer>
        <div class="text-right">
          <button class="btn btn-link" style="font-size: 22px;" @click="$bvModal.hide('order-success')">
            <fa :icon="['far', 'times-circle']" />
          </button>
        </div>
        <div v-if="currentItem" class="order">
          <h5 class="text-white">
            商品结算
          </h5>

          <long-store-item :item="currentItem" />
          <div class="purchase">
            <btn
              class="btn pt-3 pb-3 btn-block btn-primary"
              :disabled="purchaseLoading"
              :loading="purchaseLoading"
              @click="purchase(currentItem)"
            >
              确认购买 ${{ currentItem.price }}
            </btn>
          </div>
        </div>
      </b-modal>
      <!-- <div
        class="alert alert-primary mt-4"
        style="list-style: none"
        v-if="app.news"
        v-html="app.news"
      ></div> -->

      <div class="section-header mt-5">
        <div v-if="game" class="d-flex" style="width:100%">
          <tabs v-model="game" :options="types" size="sm" />

          <search-input v-model="keyword" class="ml-auto" no-right-border placeholder="搜索关键字" />

          <drop-down v-model="sort" :data="sortOptions" />
        </div>
      </div>

      <div v-if="game" class="rarities">
        <chip-select v-model="rarity" :options="game.rarities" />
      </div>

      <div class="d-flex justify-content-between flex-wrap my-4">
        <small v-if="filteredItems" class="found-label"
          ><span v-if="keyword">关键字: {{ keyword }},</span>
          <span v-if="rarity">稀有度: {{ rarity }},</span>
          发现 {{ filteredItems.length }} 件饰品
        </small>
        <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" first-number last-number />
      </div>

      <div v-if="!loading" class="inventories">
        <store-item
          v-for="(item, index) in filteredItems"
          :key="index"
          :disabled="item === currentItem"
          :item="item"
          @click="buy(item)"
        />
        <div v-if="filteredItems.length === 0 && !loading" class="no-item">
          没有找到相关商品~
        </div>
      </div>
      <div v-if="loading" class="inventories">
        <div v-for="i in 24" :key="`skeleton-${i}`" class="store-item">
          <b-skeleton height="325px" style="border-radius:8px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoreItem from './StoreItem'
import { mapGetters } from 'vuex'
import { StoreService } from '@/services/api'
import LongStoreItem from './LongStoreItem'
import ChipSelect from '@/components/ChipSelect'
import SearchInput from '@/components/SearchInput'
import DropDown from '@/components/dropdown-menu/DropDown'
// import { items } from '@/services/fakeData'

const TYPES = [
  {
    label: 'CSGO',
    value: 730,
    rarities: [
      '消费级',
      '军规级',
      '工业级',
      '匕首',
      '受限',
      '保密',
      '隐秘',
      '普通级',
      '高级',
      '非凡',
      '奇异',
      '卓越',
      '违禁',
    ],
  },
  {
    label: 'Dota2',
    value: 570,
    rarities: ['普通', '罕见', '稀有', '神话', '不朽', '传说', '至宝', '远古'],
  },
]

export default {
  name: 'Store',
  components: {
    StoreItem,
    LongStoreItem,
    ChipSelect,
    SearchInput,
    DropDown,
  },
  data() {
    return {
      items: [],
      sort: 'up',
      sortOptions: [
        {
          icon: 'chevron-up',
          value: 'up',
          label: '大 - 小',
        },
        {
          icon: 'chevron-down',
          value: 'down',
          label: '小 - 大',
        },
      ],
      loading: false,
      purchaseLoading: false,
      currentPage: 1,
      perPage: 50,
      total: 0,
      keyword: '',
      rarity: '',
      currentItem: null,
      types: TYPES,
      game: TYPES[0],
    }
  },
  mounted() {
    this.loadData()
  },
  computed: {
    filteredItems() {
      const order = this.sort === 'up' ? -1 : 1
      return this.items
        .filter(item => item.app_id === this.game.value)
        .filter(item => item.name.toLowerCase().includes(this.keyword.toLowerCase()))
        .filter(item => {
          if (!item.type) return true
          return item.type.indexOf(this.rarity) >= 0
        })
        .sort((item1, item2) => (item1.price - item2.price) * order)
    },
    ...mapGetters(['user', 'app']),
  },
  watch: {
    currentPage(page) {
      this.loadData(page)
    },
  },
  methods: {
    changeApp(game) {
      this.game = game
      this.rarity = ''
    },
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        variant: type,
      })
    },
    async loadData(page) {
      this.loading = true
      const { data } = await StoreService.getStore(page)
      this.items = data.data.map(i => {
        const rv = i.item
        rv.price = i.price
        return rv
      })
      this.perPage = data.per_page
      this.total = data.total
      this.currentInventory = null
      this.loading = false
    },
    buy(item) {
      this.$router.push(`/store/${item.market_hash_name}`)
      // this.currentItem = item
      // if (this.user.isLogin) {
      //   this.$bvModal.show('order-success')
      // } else {
      //   this.toast('请先登录')
      // }
    },
    async purchase(item) {
      try {
        this.purchaseLoading = true
        await StoreService.buyStoreItem(item.market_hash_name)
        this.purchaseLoading = false
        this.$bvModal.hide('order-success')
        this.toast(this.currentItem.name + ' 购买成功', 'success')
        this.currentItem = null
      } catch (e) {
        this.toast('购买失败！请确认你的交易链接设置正确或有足够余额')
      } finally {
        this.$store.dispatch('LOAD_USER')
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';

.store {
  .banner {
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
    position: relative;
    background: url(../../assets/banner.svg);
    background-size: cover;

    h2,
    h3 {
      color: #fff;
      font-style: normal;
      font-weight: normal;
    }

    h2 {
      font-size: 62px;
      position: absolute;
      top: 40%;
      left: 10%;
    }

    h3 {
      font-size: 40px;
      line-height: 76px;
    }
  }

  .section-header {
    margin-bottom: 2em;
    display: flex;
    align-items: flex-end;

    .type {
      width: 186px;
      height: 66px;
      line-height: 66px;
      margin-right: 30px;
      color: #fff;
      font-size: 28px;
      position: relative;
      display: flex;
      justify-content: center;

      img {
        position: absolute;
        top: 0;
      }
    }
  }
}

.found-label {
  color: #606c85;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
}

.no-item {
  width: 100%;
  height: 100px;
  color: #bbb;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rarities {
  margin-bottom: 1em;
}

.purchase {
  width: 100%;
  margin: 3em 0;
  padding: 0 2em;
}

.icon {
  font-size: 0.8em;
  color: #6242da;
}

.store-warning {
  font-size: 12px;
  margin: 2em;
  padding: 10px;
  background: rgba(214, 214, 214, 0.2);
}

.order {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__desc {
    color: #6a6996;
    font-size: 12px;
  }
}

.inventories {
  display: flex;
  flex-wrap: wrap;
}

.keyword {
  margin-left: auto;
  width: 300px;
  background-color: transparent;
  border-color: #434365;
}

@media screen and (min-width: 1080px) {
  .store-item {
    flex: 1 20%;
    max-width: 20%;
  }
}

@media screen and (min-width: 860px) and (max-width: 1080px) {
  .store-item {
    flex: 1 25%;
    max-width: 25%;
  }
}

@media screen and (min-width: 660px) and (max-width: 860px) {
  .store-item {
    flex: 1 33.33333%;
    max-width: 33.33333%;
  }
}

@media screen and (min-width: 480px) and (max-width: 660px) {
  .store-item {
    flex: 1 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .store-item {
    flex: 1 100%;
    max-width: 100%;
  }
}
</style>
