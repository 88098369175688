<template>
  <div
    class="dropdown"
    :class="{
      'dropdown--disabled': disabled,
    }"
    tabindex="0"
    @blur="close"
  >
    <div
      class="dropdown__title"
      @click="toggle"
    >
      <span v-if="title">{{ title }}</span>
      <fa
        v-if="selected === 'up'"
        :icon="['fas', `sort-amount-up`]"
        class="sort-icon"
      />
      <fa
        v-else
        :icon="['fas', `sort-amount-down-alt`]"
        class="sort-icon"
      />
      <span>价格</span>
      <fa :icon="['fas', 'chevron-down']" />
    </div>

    <transition-group
      name="list"
      tag="div"
      class="dropdown-transition"
      :class="{ 'drop-up': dropUp }"
    >
      <ul
        v-if="down"
        key="menu"
        class="dropdown__menu"
      >
        <li
          v-if="hasClose"
          class="item close-btn"
          @click="close"
        >
          <FIcon :icon="['fa', 'times']" />
        </li>
        <li
          v-for="({ label, value }, k) in data"
          :key="k"
          class="item"
          @click="select(value)"
        >
          <span>{{ label }}</span>
        </li>
      </ul>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'DropDown',
  props: {
    title: String,
    data: Array,
    dropUp: {
      type: Boolean,
      default: false,
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: [String, Number],
  },
  data() {
    return {
      down: false,
      selected: this.data[0].value,
    }
  },
  watch: {
    value(val) {
      this.selected = val
    },
  },
  mounted() {},
  methods: {
    toggle() {
      if (this.disabled) {
        this.down = false
        return
      }
      this.down = !this.down
    },
    close() {
      this.down = false
    },
    select(val) {
      this.selected = val
      this.$emit('input', val)
      this.close()
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';

.dropdown {
  width: 115px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #253043;
  border-radius: 0px 6px 6px 0px;
  outline: none;
  position: relative;
  z-index: 100;

  &:focus {
    box-shadow: rgba(0, 0, 0, 0.2) 0 0px 3px 0;
  }
  &__title {
    width: 100%;
    height: 100%;
    padding: 0 15px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__menu {
    width: 100%;
    padding: 0;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
    box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px 0;
    background-color: transparent;
    border: 1px solid #253043;
    list-style-type: none;
    cursor: pointer;
    overflow: hidden;
    position: absolute;

    .item {
      text-align: left;
      line-height: 40px;
      padding: 0 30px;
      user-select: none;
      &:hover {
        background-color: #282838;
      }
    }
    .close-btn {
      height: 50px;
      font-size: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.sort-icon {
  color: $primary;
}

.drop-up {
  width: 100%;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: all 0.1s ease-out;
  transform-origin: center top;
}
.drop-up {
  .list-enter-active,
  .list-leave-active,
  .list-move {
    transform-origin: center bottom;
  }
}
.list-enter {
  opacity: 0;
  transform: scaleY(0.5);
  height: 0px;
}
.list-enter-to {
  opacity: 1;
  transform: scaleY(1);
  height: 100%;
}
.list-leave-active {
  opacity: 1;
  transform: scaleY(1);
  height: 100%;
}
.list-leave-to {
  opacity: 0;
  height: 0px;
  transform: scaleY(0);
}
</style>
