<template>
  <div class="d-flex flex-wrap">
    <chip2 @click="changeValue('')" class="" :active="dValue === ''">
      不限
    </chip2>
    <chip2 @click="changeValue(name)" v-for="name in options" :key="name" :active="dValue === name">
      {{ name }}
    </chip2>
  </div>
</template>

<script>
export default {
  name: 'ChipSelect',
  props: {
    value: String,
    options: Array,
  },
  data() {
    return {
      dValue: '',
    }
  },
  watch: {
    value(val) {
      this.dValue = val
    },
  },
  methods: {
    changeValue(val) {
      this.dValue = val
      this.$emit('input', val)
    },
  },
}
</script>

<style lang="scss" scoped>
.chip--active {
  background: #71d74f66;
}
</style>
