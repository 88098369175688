<template>
  <div class="store-item">
    <div
      class="store-item-wrapper"
      :class="{ hovering: isHover }"
      @mouseover="isHover = true"
      @mouseout="isHover = false"
      @click="emit('click')"
    >
      <div class="price">{{ $currencySign }}{{ item.price }}</div>

      <div class="store-item__image">
        <img :src="imageUrl" class="store-item__img embed-responsive" />
      </div>

      <div class="store-item__bg" :class="`background--${item.rarity}`">
        <img src="@/assets/item-dot-bg.png" width="100%" style="transform:translateY(-100px)" alt="" />
      </div>

      <div class="store-item__info">
        <div class="store-item__name">
          <div ref="name" :class="{ oversize: oversize }">
            {{ nameObject.excludeWear }}
          </div>
          <div class="status">{{ item.type }}</div>
          <div class="wear">{{ nameObject.wear }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nameFilter } from '@/utils'
import { ASSET_URL } from '@/config'

export default {
  name: 'StoreItem',
  props: {
    item: Object,
    disabled: Boolean,
    dummy: Boolean,
  },
  methods: {
    emit(event) {
      this.$emit(event)
    },
  },
  mounted() {
    if (this.$refs.name && this.$refs.name.scrollWidth >= 240) {
      this.oversize = true
    }
  },
  data: function() {
    return {
      oversize: false,
      isHover: false,
    }
  },
  computed: {
    nameObject() {
      return nameFilter(this.item.name)
    },
    imageUrl() {
      return ASSET_URL + this.item.image_url
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';

.price {
  padding-top: 1em;
  padding-left: 1em;
  font-size: 1.125em;
  color: #fff;
}

@each $rarity, $radio in $radios {
  .background--#{$rarity} {
    background: $radio;
  }
}

@each $rarity, $color in $rarities {
  .background--#{$rarity} {
    border-bottom: 3px solid $color;
  }
}

.oversize {
  animation: toleft 5s linear 1s infinite alternate;
}
.buy {
  text-align: center;
  font-size: 1.2em;
  padding: 1.1em;
}
.store-item {
  padding: 10px;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;

  &-wrapper {
    width: 100%;
    height: 310px;
    overflow: hidden;
    position: relative;
    border-radius: 8px;
    background: #141a24;

    &.hovering {
      /* box-shadow: 0 0 10px 0px $primary; */
      .store-item__bg {
        background: $primary-radio;
        border-color: $primary;
      }
      .store-item__image {
        transform: scale(1.15);
      }
      .store-item__info {
        .oversize {
          color: $primary;
        }
      }
    }
  }
  &__bg {
    width: 120%;
    height: 190px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &__name {
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .wear {
      height: 16px;
      font-size: 11px;
      color: #666;
    }
    .status {
      color: #a5a5a5;
      font-size: 0.8em;
    }
  }
  &__info {
    position: absolute;
    bottom: 0;
    color: #fff;
    font-size: 14px;
    padding: 1em;
  }
  &__img {
    &:hover {
      animation: bounce 4s ease-in-out 1s infinite alternate;
    }
    -webkit-user-drag: none;
    filter: drop-shadow(0 10px 3px rgba(0, 0, 0, 0.25));
  }
  &__image {
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em;

    transition: 0.3s ease;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px) translateX(1px);
  }
  100% {
    transform: translateY(10px);
  }
}
@keyframes toleft {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-30px);
  }
}
</style>
